import React from "react";
import Toggle from "react-toggle";

import { observer } from "mobx-react-lite";

import useStore from "../../hooks/useStore";
import { BodyPart } from "../../stores/imageStore";
import BodyPartsSwitch from "../BodyPartsSwitch";
import ModeToggleSwitch from "../ModeToggleSwitch";
import Slider from "../Slider";

const ToolbarMask: React.FC = observer(() => {
  const { maskStore, aiStore, imageStore } = useStore();

  const drawingModes = ["Draw", "Erase"];
  const changeMode = (value: string) => {
    if (value === "Draw") maskStore.enableDrawMode();
    else maskStore.enableEraseMode();
  };

  const bodyParts = [
    "_dummy1",
    "Face",
    "_dummy2",
    "Right arm",
    "Torso",
    "Left arm",
    "Right leg",
    "_dummy3",
    "Left leg",
  ];
  const toggleBodyParts = (bodyPart: BodyPart) => {
    imageStore.toggleBodyPartsEnabled(bodyPart);
  };

  const multiPersonTargetGuide = {
    0: "All",
    1: "Leftmost",
    2: "2nd Left",
    3: "3nd Left",
  };

  return (
    <div className="toolbar__content">
      <div className="toolbar__block">
        <div className="slider__header">
          <p className="slider__title">Part-wise detection</p>
          <span className="slider__input">
            <Toggle
              checked={aiStore.segmentationModelMode === "bodypix"}
              icons={false}
              onChange={() => {
                aiStore.toggleSegmentationModelMode();
              }}
            />
          </span>
        </div>
        <Slider
          title="(For multi-person) how detect"
          value={maskStore.targetPerson}
          min={0}
          max={5}
          marks={multiPersonTargetGuide}
          callback={(value) => maskStore.setTargetPerson(value)}
        />
      </div>
      <div className="toolbar__block">
        <Slider
          title="Auto body detection threshold"
          value={Math.round(
            100 * (Math.log10(maskStore.detectionThreshold) + 2)
          )}
          min={0}
          max={200}
          callback={(value) =>
            maskStore.setDetectionThreshold(10 ** (value / 100 - 2))
          }
        />
      </div>
      <div className="toolbar__block">
        <div className="slider__header">
          <p className="slider__title">Body parts</p>
        </div>
        <BodyPartsSwitch
          bodyParts={bodyParts}
          onChange={toggleBodyParts}
          enabledBodyParts={imageStore.bodyPartsEnabled}
        />
      </div>
      <div className="toolbar__drawing">
        <ModeToggleSwitch
          options={drawingModes}
          changeValue={changeMode}
          initialSelectedIndex={!maskStore.isEraseMode ? 0 : 1}
        />
        <Slider
          title="Brush Size"
          value={maskStore.lineWidth}
          min={1}
          max={150}
          // disabled={maskStore.isEraseMode}
          callback={(value) => maskStore.setLineWidth(value)}
        />
        <Slider
          title="Eraser Size"
          value={maskStore.eraseLineWidth}
          min={1}
          max={150}
          // disabled={!maskStore.isEraseMode}
          callback={(value) => maskStore.setEraseLineWidth(value)}
        />
        <div className="toolbar__composite_wrapper">
          <div
            className={`toolbar__option`}
            onClick={() => maskStore.fillAll()}
          >
            Fill all
          </div>
          <div
            className={`toolbar__option`}
            onClick={() => maskStore.clearAll()}
          >
            Clear all
          </div>
        </div>
      </div>
      <div className="toolbar__block">
        <div className="slider__header">
          <p className="slider__title">Size adjustment</p>
        </div>
        <div className="toolbar__composite_wrapper">
          <div
            className={`toolbar__option`}
            onClick={async () => {
              await imageStore.morphologyMask("shrink");
            }}
          >
            Shrink
          </div>
          <div
            className={`toolbar__option`}
            onClick={async () => {
              await imageStore.morphologyMask("expand");
            }}
          >
            Expand
          </div>
        </div>
      </div>
      <div className="toolbar__composite_wrapper">
        <Slider
          title="Mask blur"
          value={Math.round(aiStore.maskBlur)}
          min={0}
          max={50}
          callback={(value) => aiStore.setMaskBlur(value)}
        />
        <Slider
          title="Anti-glare"
          value={Math.round(aiStore.antiGlare)}
          min={0}
          max={50}
          callback={(value) => aiStore.setAntiGlare(value)}
        />
      </div>

      {/* TODO サーバ側で色の等価を実装する */}
      <div className="toolbar__composite_wrapper">
        <div className="slider__header">
          <p className="slider__title">Equalize color</p>
          <span className="slider__input">
            <Toggle
              checked={!aiStore.equalizeColor}
              icons={false}
              onChange={() => {
                aiStore.toggleEqualizeColor();
              }}
            />
          </span>
        </div>
      </div>

      <div className="toolbar__composite_wrapper">
        <Slider
          title="Paste opacity"
          value={Math.round(aiStore.pasteOpacity * 100)}
          min={0}
          max={100}
          callback={(value) => aiStore.setPasteOpacity(value / 100)}
        />
        <div
          className="toolbar__option"
          onClick={aiStore.pasteGeneratedImageData}
        >
          Re-apply
        </div>
      </div>
    </div>
  );
});

export default ToolbarMask;
