import React from "react";

import { BodyPart } from "../stores/imageStore";

type Props = {
  bodyParts: string[];
  onChange: (value: BodyPart) => void;
  enabledBodyParts: BodyPart[];
};

const BodyPartsSwitch: React.FC<Props> = (props) => {
  const { bodyParts, onChange, enabledBodyParts } = props;

  return (
    <div className="container">
      <div className="body-parts-switches">
        {bodyParts.map((part) => {
          return (
            <div key={part}>
              <label htmlFor={part}>
                {part[0] === "_" ? (
                  <span className="body-parts-title-empty"></span>
                ) : (
                  <>
                    <input
                      className="body-parts-input"
                      type="checkbox"
                      id={part}
                      checked={enabledBodyParts.includes(part as BodyPart)}
                      onChange={() => {
                        onChange(part as BodyPart);
                      }}
                    />
                    <span className="option-toggle-tile">
                      <span className="option-toggle-label"> {part}</span>
                    </span>
                  </>
                )}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BodyPartsSwitch;
